.cart-button {
  margin-left: 1vw;
  margin-right: 1vw;
  color: #ffffff !important;
  border-radius: 5px;
}

.checkout {
  background-color: #f44336;
}

#shopping-cart {
  margin-top: 1vw;
}
