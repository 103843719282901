.settings {
  font-size: 1rem;
  box-sizing: unset;
}
.settings li.header-title {
  font-size: 15px;
  color: white;
}
.react-tabs__tab.react-tabs__tab--selected {
  background-color: #2e9cce;
  color: white;
}
.react-tabs__tab {
  background-color: #aaabbe;
  border-radius: 5px 5px 0 0;
  width: 24%;
}
.react-tabs__tab-list {
  border-bottom: none;
}
.settings *,
*::before,
*::after {
  box-sizing: border-box;
}
.settings .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: calc(16% + 7px);
  float: left;
  margin: 1px;
  color: white;
  text-align: center;
}
